import { Environment, EnvironmentSettings } from './EnvironmentSettings'

// TODO env variables?

export const production = new EnvironmentSettings(
  Environment.production,
  {
    dexGuruAppDomain: 'dex.guru',
    dexGuruAPIDomain: 'api.dex.guru',
    dexGuruWsDomain: 'ws.dex.guru',
    dexGuruTradingAPIDomain: 'api-trading.dex.guru',
    ogpBucketDomain: 'dg-ogp-prod.s3.us-east-1.amazonaws.com',
  },
  { dexGuruAuthMicroserviceDomain: 'auth.dex.guru' }
)

export const test = new EnvironmentSettings(
  Environment.test,
  {
    dexGuruAppDomain: 'test.dex.guru',
    dexGuruAPIDomain: 'api-stage.dex.guru',
    dexGuruWsDomain: 'ws-api-stage.dex.guru',
    dexGuruTradingAPIDomain: 'api-trading-stage.dex.guru',
    ogpBucketDomain: 'dg-ogp-test.s3.us-east-1.amazonaws.com',
  },
  { dexGuruAuthMicroserviceDomain: 'auth-api-stage.dex.guru' }
)
