import { GasFeeType, Settings } from '../model'
import { production, test } from './environments'
import { Environment } from './EnvironmentSettings'

export const appGitVersion = process.env.GIT_COMMIT
export const appName = 'dexGuru'

export const isProduction =
  process.env.REACT_APP_ENVIRONMENT?.toLocaleLowerCase() === Environment.production
export const environment = isProduction ? production : test

export const coingeckoAPIUrl = 'https://api.coingecko.com/api/v3'
export const balancesAPIUrl = 'wss://wb.dexguru.net/address'
export const balancesAPIKey = 'Zjc1ODljZDBkYTgxODVlZjIyYTViNjJi'
export const apmServerUrl =
  process.env.REACT_APP_ENVIRONMENT?.toLocaleLowerCase() === Environment.production
    ? 'https://apm.dex.guru'
    : 'https://apm-stage-nur1.dexguru.biz'

export const infuraApiKey = '407161c0da4c4f1b81f3cc87ca8310a7'
export const updatePeriodMs = 15000

export const POOL_ACTIVITY_REFRESH_TIMEOUT = 2 * 2000 // 2 sec
export const SIDEBARS_GRAPHS_REFRESH_INTERVAL = 30 * 60 * 1000 // 30 min
export const MARKET_SCREENER_REFRESH_INTERVAL = 30 * 60 * 1000 // 30 min
export const CURRENT_TOKEN_REFRESH_INTERVAL = 30 * 60 * 1000 // 30 min

export const PRICE_REFRESH_INTERVAL = 30 * 1000 // 30 sec
export const TXN_DATA_REFRESH_INTERVAL = 30 * 1000 // 30 sec
export const TXN_DATA_REFRESH_INTERVAL_FULL_SIDEBARS = 30 * 1000 // 30 sec
export const TXN_DATA_REFRESH_INTERVAL_TOKEN_PROFILE = 300 * 1000 // 30 sec

export const TOKEN_DATA_REFRESH_INTERVAL = 2 * 60 * 1000 // 2 min
export const TOKEN_TAB_REFRESH_INTERVAL = 30 * 60 * 1000 // 30 min

export const TRADER_PROFILE_REFRESH_INTERVAL = 5 * 60 * 1000 // 5 min
export const TOKEN_PROFILE_REFRESH_INTERVAL = 60 * 60 * 1000 // 1 hour

export const NETWORK_STATUS_INTERVAL = 30 * 1000 // 30 sec

export const defaultPeriodSidebarChart = 9
export const defaultPeriodSidebarFullChart = 7
export const defaultIntervalSidebarChart = 'day'
export const defaultPeriodTraderProfileChart = -1

export const referrerAddress = '0x720c9244473Dfc596547c1f7B6261c7112A3dad4'

export const walletUrl = 'https://app.zerion.io/'

// TODO [NETWORK_SUPPORT]: UNRESOLVED - soon we should have token profile (https://app.shortcut.com/dexguru/epic/5248) and get rid of coingecko data
// GET https://api.coingecko.com/api/v3/asset_platforms
export const coingeckoApiPlatformIds: Record<string, string> = {
  eth: 'ethereum',
  bsc: 'binance-smart-chain',
  polygon: 'polygon-pos',
  avalanche: 'avalanche',
  arbitrum: 'arbitrum-one',
  fantom: 'fantom',
  celo: 'celo',
}

export const MOBILE_THRESHOLD = 1025

export const DEFAULT_SLIPPAGE = 1
export const DEFAULT_GAS: GasFeeType = GasFeeType.instant
export const DEFAULT_TIP = 0

export const DEFAULT_SYMBOL_NAME = '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599-eth'

export const DEBOUNCE_INPUT = 1000

export const QUOTE_REFRESHABLE_INTERVAL = 30000

export const DEFAULT_DELAY_PRELOADER_DYOR = 1000
export const CLOSE_MODAL_TIMEOUT = 90000

export const GTM_ID = 'GTM-KGNN7R6'
export const GTM_PURCHASE_TRESHOLD_TOP = 1 * 10 ** 6 // $1M

export const MARK_NOTIFICATIONS_DELAY = 2000

export const RECALL_DELAY = 1000

export const MIN_TX_SIDEBAR__FULL = 5

export const MIN_ACCOUNT_LENGHT = 42
export const MIN_POOL_ADDRESS_LENGHT = 42

export const RPC_ERR = 'RPC_ERR'

export const FULL_HISTORY_BUTTON_THRESHOLD = 30

export const TOLERATED_OFFLINE_TIME_WITHOUT_CHART_REFRESH = 10 * 1000

export const defaultSettings: Settings = {
  slippage: DEFAULT_SLIPPAGE,
  gasFee: DEFAULT_GAS,
  allowHighSlippageTxn: false,
  isLeftDrawerOpen: true,
  isRightDrawerOpen: true,
  tvChartHeight: '',
  isChartFullScreen: false,
}

export const amountDaysAccordingToPeriod: Record<string, number> = {
  day: 1,
  week: 9,
  month: 30,
  all_time: -1,
}

export const configCategoriesColor: Record<string, string> = {
  bot: '#FFCC4D',
  heavy: '#55ACEE',
  medium: '#66757F',
  casual: '#77B255',
  rooster: '#DD2E44',
  tiger: '#FFB100',
  elephant: '#99AAB5',
  noob: '#53FFE0',
  other: '#53FFE0',
}
