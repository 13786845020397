import { model } from '@telekomconsalting/dex-guru-model'
import defaultAmmIcon from 'app-images/icons/others.svg'

import { AMM, NetworkConfigV3 } from '../model'
import { store } from '../services/reduxService'

/* border and gradient for charts
  #63EAB9; background: linear-gradient(90deg, #003687 -2.17%, #019CFF 100%); mooniswap
  #FFC555; background: linear-gradient(104.04deg, #FFC555 0%, #0148FF 100%); uniswap
  #FFFFFF; background: linear-gradient(104.04deg, #4C0584 0%, #B500AD 100%); uniswapV3
  #EA6394; background: linear-gradient(104.04deg, #98254F 0%, #5200FF 100%); pancakeswap
  #00C8B0; background: linear-gradient(104.04deg, #00C8B0 0%, #191FB7 100%); pancakeswapV2
  #0094FF; background: linear-gradient(104.04deg, #7000FF 0%, #FF6C01 100%); sushiswap
  #CF92FF; background: linear-gradient(104.04deg, #9E2FF5 0%, #FF6838 100%); dforce
  #FF7B7B; background: linear-gradient(104.04deg, #FF7B7B 0%, #097BCE 100%); default
*/

export interface AMMConfig {
  id?: string
  displayName: AMM
  name: AMM
  borderColor: string
  background: string
  logoURI: string // Logo URI
  chain_id?: number
  getGradient: (
    context: CanvasRenderingContext2D,
    x0?: number,
    y0?: number,
    x1?: number,
    y1?: number
  ) => CanvasGradient
}

export const ammDefault: AMMConfig = {
  displayName: 'Select Market',
  name: 'all',
  borderColor: '#FF7B7B',
  background: 'linear-gradient(180deg, #411694 0%, #853087 100%)',
  logoURI: defaultAmmIcon,
  getGradient: (
    ctx: CanvasRenderingContext2D,
    x0 = 0,
    y0 = 0,
    x1 = 0,
    y1 = 300
  ): CanvasGradient => {
    const gradient = ctx.createLinearGradient(x0, y0, x1, y1)
    gradient.addColorStop(0, 'rgba(255, 123, 123, 0.6)')
    gradient.addColorStop(1, 'rgba(9, 123, 206, 0.6)')
    return gradient
  },
}

export const mapBeAmmConfigToFe = (beConfig: model.AmmConfig): AMMConfig => {
  return {
    id: beConfig.id,
    chain_id: beConfig.chain_id,
    displayName: beConfig.display_name,
    name: beConfig.name,
    borderColor: beConfig.border_color,
    background: `linear-gradient(180deg, ${beConfig.start_gradient_color} 0%, ${beConfig.end_gradient_color} 100%)`,
    getGradient: (ctx: CanvasRenderingContext2D): CanvasGradient => {
      const gradient = ctx.createLinearGradient(0, 0, 0, 400)
      gradient.addColorStop(0, beConfig.start_gradient_color)
      gradient.addColorStop(1, beConfig.end_gradient_color)
      return gradient
    },
    logoURI: beConfig.logo_uri || defaultAmmIcon,
  }
}

export const getAmmConfig = (amm: AMM): AMMConfig => {
  const amms = new Set(store.getState().networksConfig.data.flatMap((x) => x.amms))
  const beAmmConfig = Array.from(amms).find((x) => x.name === amm)
  if (!beAmmConfig) {
    return ammDefault
  }
  return mapBeAmmConfigToFe(beAmmConfig)
}

export const getAmmConfigById = (id: string): AMMConfig => {
  const amms = new Set(store.getState().networksConfig.data.flatMap((x) => x.amms))
  const beAmmConfig = Array.from(amms).find((x) => x.id === id)
  if (!beAmmConfig) {
    return ammDefault
  }
  return mapBeAmmConfigToFe(beAmmConfig)
}

export const getFilteredAMM = (network?: string): AMMConfig[] => {
  const filteredNetwork = (store.getState().networksConfig.data as NetworkConfigV3[]).filter(
    (item) => (network ? item.name === network : true)
  )
  const amms = Array.from(new Set(filteredNetwork.flatMap((x) => x.amms)))
  if (amms.length === 0) {
    return [ammDefault]
  }
  return [ammDefault, ...amms.map((amm) => mapBeAmmConfigToFe(amm))]
}
